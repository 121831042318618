import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../components/seo';

import * as Gs from '../globals/styledComponents.js';

import Layout from '../components/layout';
import Row from '../components/Row';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { MARKS, BLOCKS } from '@contentful/rich-text-types';
import RichText from '@madebyconnor/rich-text-to-jsx';
import Footer from '../components/footer';


const StyledSection = styled.section`
    ${tw`relative flex items-center w-full`};
`

const RichPara = styled.p`
    ${tw`font-secondary font-light text-grey-darkest my-12`};
    font-size: ${Gs.modularScale}rem;
    line-height: 1.618;
    letter-spacing: 0.01em;
`

const RichH1 = styled.h1`
    
`

const RichH2 = styled.h2`

    ${tw``};
    font-size: ${Gs.modularScale * 2.5}rem;

`

const RichH3 = styled.h3`
    font-size: ${Gs.modularScale * 2}rem;
`

const RichH4 = styled.h4`
    font-size: ${Gs.modularScale * 1.5}rem;
`

const RichH5 = styled.h5`
    font-size: ${Gs.modularScale}rem;
    `

const RichH6 = styled.h6`
    font-size: ${Gs.modularScale * 0.8}rem;
`

const RichOL = styled.ol`
     ${tw`font-light text-grey font-primary my-24`};
`

const RichUL = styled.ul`
     ${tw`font-light text-grey font-primary my-24`};
`

const RichLI = styled.li`
`

const RichQuote = styled.p`
    p{
        ${tw`font-light text-grey font-primary my-24`};
        letter-spacing: -0.01em;
        line-height: 1.618;
        font-size: ${Gs.modularScale * 1.5}rem;
        @media min-width(992px){
            font-size: ${Gs.modularScale * 2}rem;
        }
    }
    @media min-width(992px){
        p{
            font-size: ${Gs.modularScale * 2}rem;
        }
    }
`

const EmbeddedAsset = styled.img`
`

const Title = styled.h1`
    ${tw`text-gr font-serif font-light`};
`
const RichTextWrapper = styled.div`
    h2, h3, h4, h6{
        // font-size: 1.414rem;
    }
    p{
        font-size: 1.618rem;
        line-height: 1.618;
    }
`

const MarkDownHolder = styled.div`
    ${tw`overflow-x-hidden`};
    font-size; 1.2rem;

    h3{
        ${tw`mt-18 md:mt-18 lg:mt-18`};
        //Margin top repeated for all break points,
        //because something's being overridden
        font-size: ${1.414 * 2}rem;
    }
    h4{
        ${tw`mt-18 md:mt-18 lg:mt-18 lg:w-4/5`};
        //Margin top repeated for all break points,
        //because something's being overridden
        line-height: 1.618;
        font-size: ${1.414 * 1.2}rem;
    }
    p{
        ${tw`font-secondary font-normal md:font-normal mb-10 md:mb-10 mt-2 md:mt-2`};
        line-height: 1.618;
        font-size: 1.2rem;
        @media (min-width: 768px){
            font-size: 1.2rem;

        }
    }
    blockquote{
        ${tw`font-serif text-xl italic py-16`}
        p{
            ${tw`font-serif font-light text-2xl italic`}
        }
    }
    img{
        ${tw`my-8 h-auto`};
        width: 100%;
    }
`

const BlogPost = (props) => {

    const post = props.data.stuff;
    const seo = props.data.seo;
    console.log(props.data);
    let document = post.json;
    // console.log(JSON.parse(document));
    // console.log(documentToReactComponents(JSON.parse(document)));

    const RichTextOverrides = {
        [BLOCKS.PARAGRAPH]: {
            component: MyParagraph,
        },
        [BLOCKS.HEADING_1]: {
            component: Heading1,
        },
        [BLOCKS.HEADING_2]: {
            component: Heading2,
        },
        [BLOCKS.HEADING_3]: {
            component: Heading3,
        },
        [BLOCKS.HEADING_4]: {
            component: Heading4,
        },
        [BLOCKS.HEADING_5]: {
            component: Heading5,
        },
        [BLOCKS.HEADING_6]: {
            component: Heading6,
        },
        [BLOCKS.UL_LIST]: {
            component: UnorderedList,
        },
        [BLOCKS.OL_LIST]: {
            component: OrderedList,
        },
        [BLOCKS.QUOTE]: {
            component: Quote,
        },
        [BLOCKS.EMBEDDED_ENTRY]: {
            image: {
                component: Image,
                props: {
                    className: 'image--fullwidth'
                }
            }
        }
    }

    const options = {
        renderMark: {
            [MARKS.BOLD]: text => <strong>{text}</strong>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
        },
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
                let file = node.data.target.fields;
                debugger;
                console.log(`Node: ${node} \n File: ${file} \n Children: ${children}`);
                return <div>{children}</div>;
            }
        }
    }

    return (

        <Layout withDarkMenu>
            <SEO {...seo.seo} />
            <StyledSection css={`${tw``}; height: 64vh;`}>

                <Row css={`${tw`justify-center`}`}>

                    <div css={`${tw`lg:w-4/5 w-full px-4 mt-24`}`}>
                        <p css={`${tw`font-semibold text-grey-lighter opacity-60 tracking-wide uppercase`}`}>Blog Post</p>
                        <h1 css={`${tw`font-serif font-light text-150gr text-grey`}; letter-spacing: 0.005em;`}>
                            {post.title}
                        </h1>
                        <div css={`${tw`lg:w-2/3 w-full flex items-center mt-12 lg:mt-12`}`}>
                            <img src={post.author.image.file.url} css={`${tw`h-16 w-16 rounded-full mr-4`}`} />
                            <div>
                                <p css={`${tw`ml-2 lg:ml-6 my-0 leading-none lg:text-xl font-semibold font-primary`};`}>{post.author.name}</p>
                                <p css={`${tw`ml-2 lg:ml-6 my-0 leading-none text-sm lg:text-base font-light font-primary opacity-80`};`}>{post.publishDate}</p>
                            </div>
                        </div>
                    </div>

                </Row>

            </StyledSection>

            <StyledSection css={`${tw`flex flex-wrap`}`}>
                <div css={`${tw`w-screen flex`}`}>
                    <Img fluid={post.bannerImage.assets[0].fluid}
                        css={`${tw`w-full`}; border-radius: 2rem 2rem 0 0; height: 61.8vh;`}
                        alt={post.bannerImage.assets[0].title}
                    />
                </div>
                <Row justifyCenter>
                    <div css={`${tw`w-full lg:w-4/5 px-4 lg:px-8 pt-24 pb-44`}`}>
                        {/* <RichText
                            richText={document}
                            overrides={RichTextOverrides}
                        /> */}
                        {/* {documentToReactComponents(props.data.stuff.body.json, options)} */}
                        <MarkDownHolder
                            dangerouslySetInnerHTML={{
                                __html: post.markDown.childMarkdownRemark.html,
                            }}
                        />
                    </div>
                </Row>
            </StyledSection>

            <Footer />
        </Layout>

    );
}

export default BlogPost;

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        seo: contentfulBlogPost(slug : {eq:$slug}){
            seo{
                title: pageTitle
                metaDescription
                author
                keywords
                url
                image{
                    file{
                        url
                    }
                    description
                }
            }
        }
        stuff:contentfulBlogPost(slug: { eq: $slug }) {
            
            bannerImage{
                assets{
                    fluid(quality: 100){
                        ...GatsbyContentfulFluid_withWebp
                    }
                    title
                    description
                }
            }
            title

            author{
                
                name
                image{
                    file{
                        url
                    }
                }

            }
            publishDate(formatString: "MMMM Do, YYYY")
            markDown{
                childMarkdownRemark {
                    html
                }
            }
        }
    }
`

const MyParagraph = ({ children, ...props }) => (
    <RichPara {...props}>{children}</RichPara>
);

const Heading1 = ({ children, ...props }) => (
    <RichH1>{children}</RichH1>
);

const Heading2 = ({ children, ...props }) => (
    <RichH2>{children}</RichH2>
);

const Heading3 = ({ children, ...props }) => (
    <RichH3>{children}</RichH3>
);

const Heading4 = ({ children, ...props }) => (
    <RichH4>{children}</RichH4>
);

const Heading5 = ({ children, ...props }) => (
    <RichH5>{children}</RichH5>
);

const Heading6 = ({ children, ...props }) => (
    <RichH6>{children}</RichH6>
);

const UnorderedList = ({ children, ...props }) => (
    <Title css={tw`w-3xl`}>{children}</Title>
);

const OrderedList = ({ children, ...props }) => (
    <Title css={tw`w-3xl`}>{children}</Title>
);

const Quote = (props) => (
    <RichQuote>{props.children}</RichQuote>
);

// const Image = (props) => (
//     <EmbeddedAsset>{props.children}</EmbeddedAsset>
// )

const Image = ({ file, title, className }) => (
    <img className={className} src={file.url} alt={title} />
);
